import { get } from "lodash";
import { Navigate, useRoutes } from "react-router-dom";
import { useAppSelector } from "../hooks/useSelector";
import DashboardLayout from "../layouts/Dashboard";
import MinimalLayout from "../layouts/Minimal";
import AccountProfilePage from "../pages/AccountProfilePage";
import Approval from "../pages/Approval";
import AttendanceDashboard from "../pages/AttendanceDashboard";
import AttendanceMonitor from "../pages/AttendanceMonitor";
import AttendancePage from "../pages/AttendancePage";
import BranchGroup from "../pages/branch-group/BranchGroup";
import BranchGroupDetail from "../pages/branch-group/BranchGroupDetail";
import BranchGroupEditPage from "../pages/branch-group/BranchGroupEditPage";
import Branch from "../pages/branch/Branch";
import BranchDetail from "../pages/branch/BranchDetail";
import BranchCreate from "../pages/branch/BranchEditPage";
import SplitRevenueEditPage from "../pages/branch/SplitRevenueEditPage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import Checkin from "../pages/Checkin";
import Division from "../pages/Division";
import DivisionEditPage from "../pages/DivisionEditPage";
import EmployeeGroup from "../pages/employee-group/EmployeeGroup";
import EmployeeGroupDetail from "../pages/employee-group/EmployeeGroupDetail";
import EmployeeGroupEditPage from "../pages/employee-group/EmployeeGroupEditPage";
import EmployeeCreate from "../pages/employee/EmployeeCreate";
import EmployeeDetail from "../pages/employee/EmployeeDetail";
import EmployeeEdit from "../pages/employee/EmployeeEdit";
import EmployeeList from "../pages/employee/EmployeeList";
import ForgotPage from "../pages/ForgotPage";
import LoadingPage from "../pages/LoadingPage";
import PageNotFound from "../pages/PageNotFound";
import AllReport from "../pages/report/AllReport";
import Report from "../pages/report/Report";
import ReportRange from "../pages/report/ReportRange";
import ReportCreate from "../pages/report/ReportEditPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import InputSales from "../pages/sales/InputSales";
import ListSales from "../pages/sales/ListSales";
import MonthlySales from "../pages/sales/MonthlySales";
import SalesDashboard from "../pages/sales/SalesDashboard";
import SignInPage from "../pages/SignInPage";
import UserCreate from "../pages/user/UserCreate";
import UserEdit from "../pages/user/UserEdit";
import UserList from "../pages/user/UserList";
import SalesPerformance from "../pages/sales/SalesPerformance";
import ReimburseList from "../pages/reimburse/ListReimburse";
import CreateReimburse from "../pages/reimburse/CreateReimburse";
import ReimburseDetail from "../pages/reimburse/ReimburseDetail";

export default function Routes() {
  const { user } = useAppSelector((state) => state.global);
  const chooseDefaultRoute: any = () => {
    const role = get(user, "roleid");
    if (role === 1 || role === 2 || role === 3) {
      return { element: <Navigate to="/app/report" />, index: true };
    }
    if (role === 4 || role === 5) {
      return { element: <Navigate to="/app/division" />, index: true };
    }
    if (role === 6) {
      return { element: <Navigate to="/app/checkin" />, index: true };
    }
    if (role === 8) {
      return { element: <Navigate to="/app/sales" />, index: true };
    }
    if (role === 9) {
      return { element: <Navigate to="/app/dashboard" />, index: true };
    }
    return { element: <LoadingPage />, index: true };
  };
  return useRoutes([
    {
      path: "/app",
      element: <DashboardLayout />,
      children: [
        {
          path: "/app",
          children: [chooseDefaultRoute()],
        },
        {
          path: "/app/profile",
          children: [
            { element: <AccountProfilePage />, index: true },
            {
              element: <ChangePasswordPage />,
              path: "/app/profile/change-password",
            },
          ],
        },
        {
          path: "/app/attendance",
          children: [
            { element: <AttendanceMonitor />, index: true },
            {
              element: <AttendanceDashboard />,
              path: "/app/attendance/dashboard",
            },
            {
              element: <AttendancePage />,
              path: "/app/attendance/detail/:id",
            },
            {
              element: <EmployeeDetail />,
              path: "/app/attendance/:id",
            },
          ],
        },
        {
          path: "/app/branch",
          children: [
            { element: <Branch />, index: true },
            {
              element: <BranchCreate />,
              path: "/app/branch/edit/:id",
            },
            {
              element: <BranchCreate />,
              path: "/app/branch/create-branch",
            },
            {
              element: <BranchDetail />,
              path: "/app/branch/:id",
            },
            {
              element: <SplitRevenueEditPage />,
              path: "/app/branch/split-revenue/:id",
            },
          ],
        },
        {
          path: "/app/branch-group",
          children: [
            { element: <BranchGroup />, index: true },
            {
              element: <BranchGroupEditPage />,
              path: "/app/branch-group/edit/:id",
            },
            {
              element: <BranchGroupEditPage />,
              path: "/app/branch-group/create",
            },
            {
              element: <BranchGroupDetail />,
              path: "/app/branch-group/:id",
            },
          ],
        },
        {
          path: "/app/employee-group",
          children: [
            { element: <EmployeeGroup />, index: true },
            {
              element: <EmployeeGroupEditPage />,
              path: "/app/employee-group/edit/:id",
            },
            {
              element: <EmployeeGroupEditPage />,
              path: "/app/employee-group/create",
            },
            {
              element: <EmployeeGroupDetail />,
              path: "/app/employee-group/:id",
            },
          ],
        },
        {
          path: "/app/division",
          children: [
            { element: <Division />, index: true },
            {
              element: <DivisionEditPage />,
              path: "/app/division/edit/:id",
            },
            {
              element: <DivisionEditPage />,
              path: "/app/division/create",
            },

            {
              element: <BranchDetail />,
              path: "/app/division/:id",
            },
          ],
        },
        {
          path: "/app/approval",
          children: [{ element: <Approval />, index: true }],
        },
        {
          path: "/app/reimburse",
          children: [
            { element: <ReimburseList />, index: true },
            {
              element: <CreateReimburse />,
              path: "/app/reimburse/create",
            },
            {
              element: <CreateReimburse />,
              path: "/app/reimburse/edit/:id",
            },
            {
              element: <ReimburseDetail />,
              path: "/app/reimburse/:id",
            },
          ],
        },
        {
          path: "/app/report",
          children: [
            { element: <Report />, index: true },
            {
              element: <ReportCreate />,
              path: "/app/report/edit/:id",
            },
            {
              element: <ReportCreate />,
              path: "/app/report/create-report",
            },
            {
              element: <AllReport />,
              path: "/app/report/all",
            },
          ],
        },
        {
          path: "/app/range-report",
          children: [
            { element: <ReportRange />, index: true },
          ]
        },
        {
          path: "/app/users",
          children: [
            { element: <UserList />, index: true },
            {
              element: <UserCreate />,
              path: "/app/users/create-user",
            },
            {
              element: <UserEdit />,
              path: "/app/users/edit/:id",
            },
          ],
        },
        {
          path: "/app/employee",
          children: [
            { element: <EmployeeList />, index: true },
            {
              element: <EmployeeCreate />,
              path: "/app/employee/create-employee",
            },
            {
              element: <EmployeeEdit />,
              path: "/app/employee/edit/:id",
            },
            {
              element: <EmployeeDetail />,
              path: "/app/employee/:id",
            },
          ],
        },
        {
          path: "/app/sales",
          children: [
            { element: <ListSales />, index: true },
            {
              element: <MonthlySales />,
              path: "/app/sales/monthly",
            },
          ],
        },
        {
          path: "/app/sales-performance",
          children: [
            { element: <SalesPerformance />, index: true },
          ],
        },
        {
          path: "/app/dashboard",
          children: [
            { element: <SalesDashboard />, index: true },
            {
              element: <InputSales />,
              path: "/app/dashboard/input-sales",
            },
            {
              element: <InputSales />,
              path: "/app/dashboard/input-sales/:id",
            },
          ],
        },
        {
          path: "/app/checkin",
          children: [{ element: <Checkin />, index: true }],
        },
        {
          path: "/app/users",
          element: <Navigate to="/app/users/manage" />,
        },
        {
          path: "*",
          element: <PageNotFound fullWidth={false} />,
        },
      ],
    },
    {
      path: "/",
      element: <MinimalLayout />,
      children: [
        {
          path: "/",
          element: <SignInPage />,
        },
        {
          path: "/forgot-password",
          element: <ResetPasswordPage />,
        },
        {
          path: "/reset-password",
          element: <ForgotPage />,
        },
        {
          path: "*",
          element: <PageNotFound fullWidth />,
        },
      ],
    },
  ]);
}
