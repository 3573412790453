import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CookieJs from "js-cookie";
import _, { get, update } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import branch from "../../services/branch";
import employeeService from "../../services/employee";
import reimburseService from "../../services/reimburse";
import queryDefault from "../../utils/queryDefault";
import formConfig from "./formConfig"; // Import createFormConfig

export default function ReimburseForm() {
  const { id } = useParams();
  const user = JSON.parse(CookieJs.get("USER") ?? "");
  const [formType, setFormType] = useState("");
  const [kategori, setKategori] = useState(""); // State for category selection
  const [kategoriDetailOptions, setKategoriDetailOptions] = useState<any>([]); // State for options related to the selected kategori
  const [cabang, setCabang] = useState([]);
  const [kategoriDetail, setKategoriDetail] = useState("");
  const [division, setDivision] = useState("");
  const notification = useNotification();
  const navigate = useNavigate();
  const [pdfFile, setPdfFile] = useState<File[]>([]);
  const [excelFile, setExcelFile] = useState<File[]>([]);
  const [employeeId, setEmployeeId] = useState("");

  const [kebutuhan, setKebutuhan] = useState({
    makan: false,
    bensin: false,
    hotel: false,
    etoll: false,
    sewa_mobil: false,
    perbantuan_loading: false,
  });

  const createReimburse = useMutation(
    "create-report",
    reimburseService.createReimburse,
    {
      onSuccess: (response) => {
        notification.onOpen({
          message:
            response.message === ""
              ? "Reimburse was successfully created!"
              : response.message,
          type: "success",
          position: "top",
        });
        navigate("/app/reimburse");
      },
      onError: (error: any) => {
        notification.onOpen({
          message:
            error?.response?.data?.message === undefined
              ? "Something went wrong"
              : error?.response?.data?.message,
          type: "error",
          position: "top",
        });
      },
      ...queryDefault,
    }
  );

  const updateReimburse = useMutation(
    "update-reimburse",
    reimburseService.updateReimburse,
    {
      onSuccess: (response) => {
        notification.onOpen({
          message:
            response.message === ""
              ? "Reimburse was successfully updated!"
              : response.message,
          type: "success",
          position: "top",
        });
        navigate("/app/reimburse");
      },
      onError: (error: any) => {
        notification.onOpen({
          message:
            error?.response?.data?.message === undefined
              ? "Something went wrong"
              : error?.response?.data?.message,
          type: "error",
          position: "top",
        });
      },
      ...queryDefault,
    }
  );

  const listCabang = useQuery(
    ["cabang", {}],
    ({ queryKey }) => {
      return branch.getAllBranch({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const schema = yup.object().shape({
    type: yup
      .string()
      .required("type is a required field")
  });

  const form = useForm({
    defaultValues: {
      type: "",
      name: "",
      division: "",
      start_date: "",
      end_date: "",
      cabang: "",
      keterangan: "",
      event: "",
      kategori: "",
      total: 0,
      pdf_file: "",
      bank: "",
      rekening: "",
      atas_nama: "",
      excel_file: "",
      kategori_detail: "",
    },
    resolver: yupResolver(schema),
  });

  const updateKebutuhan = (inputString: string) => {
    // Convert the input string to an array
    const itemsToUpdate = inputString?.split(", ").map((item) => item.trim());

    // Update the state
    if (itemsToUpdate?.length > 0) {
      setKebutuhan((prevState: any) => {
        const updatedState = { ...prevState };
        itemsToUpdate.forEach((key) => {
          if (key in updatedState) {
            updatedState[key] = true;
          }
        });
        return updatedState;
      });
    }
  };

  useEffect(() => {
    employeeService
      .getEmployeeByUserId(user.id)
      .then((response) => {
        setDivision(response.data.division_name);
        setEmployeeId(response.data.id);
      })
      .catch((_) => {
        notification.onOpen({
          message: "Error getting data from server",
          type: "error",
          position: "top",
        });
      });
  }, []);

  useEffect(() => {
    if (id) {
      reimburseService
        .getReimburseById(Number(id))
        .then(({ data }) => {
          form.setValue("type", get(data, "type"));
          setFormType(get(data, "type"));
          form.setValue("kategori", get(data, "kategori"));
          form.setValue("event", get(data, "event"));
          setKategori(get(data, "kategori"));
          form.setValue("atas_nama", get(data, "atas_nama"));
          form.setValue("bank", get(data, "bank"));
          form.setValue("cabang", get(data, "cabang"));
          form.setValue("end_date", get(data, "end_date"));
          form.setValue("excel_file", get(data, "excel_file"));
          form.setValue("keterangan", get(data, "keterangan"));
          form.setValue("pdf_file", get(data, "pdf_file"));
          form.setValue("rekening", get(data, "rekening"));
          form.setValue("start_date", get(data, "start_date"));
          form.setValue("total", get(data, "total"));
          updateKebutuhan(get(data, "kategori_kebutuhan", ""));
          form.setValue("kategori_detail", get(data, "kategori_detail"));
          setKategoriDetail(get(data, "kategori_detail"));
        })
        .catch((error) => {
          console.log(error);
          notification.onOpen({
            message: "Error get data",
            type: "error",
            position: "top",
          });
        });
    }
  }, [id]);

  useEffect(() => {
    form.setValue("name", user.username);
    form.setValue("division", division);
  }, [formType]);

  // Creating formConfig once the data is loaded
  useEffect(() => {
    if (listCabang.isFetched) {
      setCabang(listCabang.data?.data?.map((cabang: any) => cabang.name));
    }
  }, [listCabang.isFetched]);

  const handleTypeChange = (value: string) => {
    setFormType(value);
    form.reset();
    form.setValue("type", value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKebutuhan({
      ...kebutuhan,
      [event.target.name]: event.target.checked,
    });
  };

  const handleKategoriChange = (value: string) => {
    setKategori(value);
    if (!!id) setKategoriDetail("");
    form.setValue("kategori", value);
  };

  useEffect(() => {
    switch (kategori) {
      case "Cabang":
        setKategoriDetailOptions(cabang || []);
        break;
      case "Event":
        // Load event-related options
        setKategoriDetailOptions(["Event1", "Event2", "Event3"]);
        break;
      case "Shutter":
        // Load shutter-related options
        setKategoriDetailOptions(["Shutter1", "Shutter2", "Shutter3"]);
        break;
      case "Express":
        // Load express-related options
        setKategoriDetailOptions(["Express1", "Express2", "Express3"]);
        break;
      default:
        setKategoriDetailOptions([]);
        break;
    }
  }, [kategori]);

  const onSubmit = (data: any) => {
    let errors = "";
    formConfig[formType]?.forEach((field) => {
      if (field.required && !data[field.name]) {
        errors += `${field.label} is required. `;
      }
    });
    if (Object.keys(errors).length > 0) {
      notification.onOpen({
        message: errors,
        type: "error",
        position: "top",
      });
      return;
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "pdf_file" && pdfFile.length > 0) {
        formData.append("reimburse_pdf", pdfFile[0]);
      } else if (key === "excel_file" && excelFile.length > 0) {
        formData.append("reimburse_excel", excelFile[0]);
      } else if (key === "start_date" || key === "end_date") {
        formData.append(
          key,
          moment(dayjs(data[key]).toDate()).format("YYYY-MM-DD")
        );
      } else {
        formData.append(key, data[key]);
      }
    });
    formData.append(
      "kategori_kebutuhan",
      _.join(_.keys(_.pickBy(kebutuhan)), ", ")
    );
    formData.append("employee_id", employeeId);
    if (id) {
      formData.append("id", id);
      updateReimburse.mutate(formData);
    } else {
      createReimburse.mutate(formData);
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    if (event.target.files) {
      const fileList = Array.from(event.target.files);
      if (field === "pdf_file") {
        setPdfFile(fileList);
        form.setValue("pdf_file", event.target.files[0].name);
      }
      if (field === "excel_file") {
        setExcelFile(fileList);
        form.setValue("excel_file", event.target.files[0].name);
      }
    }
  };

  return (
    <MainLayout>
      <Breadcrumb
        label={id ? "Edit Reimburse" : "Create Reimburse"}
        breadcrumbs={[{ label: "Reimburse List", href: "/app/reimburse" }]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 5, 6]}>
        <Box sx={{ mt: 2 }}>
          <Grid container>
            <Grid item lg={4} xs={12}>
              <FormControl fullWidth>
                <FormLabel required>Type</FormLabel>
                <Controller
                  name="type"
                  control={form.control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={form.getValues("type")}
                      onChange={(e) => handleTypeChange(e.target.value)}
                      fullWidth
                      defaultValue={form.getValues("type")}
                    >
                      <MenuItem value="">Select Type</MenuItem>
                      {Object.keys(formConfig).map((type, index) => (
                        <MenuItem key={index} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText error>
                  {form.formState.errors.type?.message}
                </FormHelperText>
              </FormControl>

              {formType && formConfig[formType] && (
                <Box mt={2}>
                  {formConfig[formType].map((field: any) => (
                    <>
                      {field.name === "kategori" ? (
                        <>
                          {/* Category selection */}
                          <FormControl fullWidth margin="normal">
                            <FormLabel required>Kategori</FormLabel>
                            <Select
                              value={kategori}
                              onChange={(e) =>
                                handleKategoriChange(e.target.value)
                              }
                              fullWidth
                            >
                              <MenuItem value="">Select Kategori</MenuItem>
                              {["Cabang", "Event", "Shutter", "Express"].map(
                                (kategori) => (
                                  <MenuItem key={kategori} value={kategori}>
                                    {kategori}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>

                          {/* Category detail input */}
                          {kategori && (
                            <FormControl fullWidth margin="normal">
                              <FormLabel
                                required
                              >{`${kategori} Details`}</FormLabel>
                              <Select
                                value={kategoriDetail}
                                onChange={(e) => {
                                  form.setValue(
                                    "kategori_detail",
                                    e.target.value
                                  );
                                  setKategoriDetail(e.target.value);
                                }}
                                fullWidth
                              >
                                <MenuItem value="">
                                  Select {kategori} Detail
                                </MenuItem>
                                {kategoriDetailOptions.map(
                                  (option: any, index: number) => (
                                    <MenuItem key={index} value={option}>
                                      {option}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          )}
                        </>
                      ) : field.name === "cabang" ? (
                        <>
                          <FormControl fullWidth>
                            <FormLabel required>Cabang</FormLabel>
                            <Controller
                              name="cabang"
                              control={form.control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  value={form.getValues("cabang")}
                                  fullWidth
                                >
                                  <MenuItem value="">Select Cabang</MenuItem>
                                  {cabang.map((option: any, index) => (
                                    <MenuItem key={index} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                            <FormHelperText error>
                              {form.formState.errors.type?.message}
                            </FormHelperText>
                          </FormControl>
                        </>
                      ) : field.name === "kategori_kebutuhan" ? (
                        <>
                          <FormControl fullWidth>
                            <FormLabel component="legend">
                              Kategori Kebutuhan
                            </FormLabel>

                            <FormGroup>
                              <Box>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={kebutuhan.makan}
                                      onChange={handleChange}
                                      name="makan"
                                    />
                                  }
                                  label="Makan"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={kebutuhan.bensin}
                                      onChange={handleChange}
                                      name="bensin"
                                    />
                                  }
                                  label="Bensin"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={kebutuhan.hotel}
                                      onChange={handleChange}
                                      name="hotel"
                                    />
                                  }
                                  label="Hotel"
                                />
                              </Box>
                              <Box>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={kebutuhan.etoll}
                                      onChange={handleChange}
                                      name="etoll"
                                    />
                                  }
                                  label="Etoll"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={kebutuhan.sewa_mobil}
                                      onChange={handleChange}
                                      name="sewa_mobil"
                                    />
                                  }
                                  label="Sewa Mobil"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={kebutuhan.perbantuan_loading}
                                      onChange={handleChange}
                                      name="perbantuan_loading"
                                    />
                                  }
                                  label="Perbantuan Loading"
                                />
                              </Box>
                            </FormGroup>
                          </FormControl>
                        </>
                      ) : (
                        <>
                          <FormControl
                            key={field.name}
                            fullWidth
                            margin="normal"
                          >
                            <FormLabel required={field.required}>
                              {field.label}
                            </FormLabel>
                            <Controller
                              name={field.name}
                              control={form.control}
                              disabled={field.disabled}
                              render={({ field: inputProps }) => {
                                if (field.type === "date") {
                                  return (
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        {...inputProps}
                                        onChange={(date) =>
                                          form.setValue(field.name, date)
                                        }
                                        renderInput={(params) => (
                                          <TextField {...params} fullWidth />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  );
                                } else if (field.type === "file") {
                                  return (
                                    <>
                                      <input
                                        type="file"
                                        name={field.name}
                                        multiple
                                        onChange={(event) =>
                                          handleFileChange(event, field.name)
                                        }
                                        hidden
                                      />
                                      <FormHelperText>
                                        <strong>{field.note}</strong>
                                      </FormHelperText>
                                      <Button
                                        variant="outlined"
                                        onClick={() => {
                                          let element: HTMLElement =
                                            document.querySelector(
                                              `input[name="${field.name}"]`
                                            )!;
                                          element.click();
                                        }}
                                      >
                                        Upload File {field.label}
                                      </Button>
                                      <FormHelperText variant="filled">
                                        {field.name === "pdf_file"
                                          ? pdfFile[0]?.name
                                          : excelFile[0]?.name}
                                      </FormHelperText>
                                    </>
                                  );
                                }
                                return (
                                  <TextField
                                    {...inputProps}
                                    type={field.type || "text"}
                                    fullWidth
                                  />
                                );
                              }}
                            />
                          </FormControl>
                        </>
                      )}
                    </>
                  ))}
                </Box>
              )}

              <Stack direction="row" spacing={2} mt={3}>
                <Button
                  variant="contained"
                  onClick={form.handleSubmit(onSubmit)}
                >
                  SUBMIT
                </Button>
                <Button variant="outlined" onClick={() => navigate(-1)}>
                  CANCEL
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
