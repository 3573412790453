import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import CookieJs from "js-cookie";
import { default as Moment } from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useNotification from "../../hooks/useNotification";
import reimburseService from "../../services/reimburse";
import config from "../../utils/config";
import formatToIDR from "../../utils/formatIDR";
import formConfig from "./formConfig";

const containsField = (type: any, field: String) => {
  return formConfig[type]?.find((attr: any) => attr.name === field);
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflow: "scroll"
};

export default function ReimburseDetail() {
  const { id } = useParams();
  const user = JSON.parse(CookieJs.get("USER") ?? "");
  const notification = useNotification();
  const [data, setData] = useState({
    employee_username: "",
    type: "",
    status: "",
    submitted_time: Moment(new Date()).format('YYYY-MM-DD'),
    approved_by: "",
    approved_time: Moment(new Date()).format('YYYY-MM-DD'),
    total: 0,
    bank: "",
    rekening: "",
    atas_nama: "",
    start_date: Moment(new Date()).format('YYYY-MM-DD'),
    end_date: Moment(new Date()).format('YYYY-MM-DD'),
    cabang: "",
    keterangan: "",
    bukti_invoice: "",
    kategori: "",
    event: "",
    quantity: 0,
    kategori_kebutuhan: "",
    pdf_file: "",
    excel_file: "",
    kategori_detail: "",
    bukti_reimburse: "",
    alasan_rejected: "",
  });

  useEffect(() => {
    if (id) {
      reimburseService.getReimburseById(Number(id)).then(({ data }) => {
        setData({
          employee_username: data.employee_username ?? "-",
          type: data.type ?? "-",
          status: data.status ?? "-",
          submitted_time: data.submitted_time ?? "-",
          approved_by: data.approved_by ?? "-",
          approved_time: data.approved_time ?? "-",
          total: data.total ?? 0,
          bank: data.bank ?? "-",
          rekening: data.rekening ?? "-",
          atas_nama: data.atas_nama ?? "-",
          start_date: data.start_date ?? "-",
          end_date: data.end_date ?? "-",
          cabang: data.cabang ?? "-",
          keterangan: data.keterangan ?? "-",
          bukti_invoice: data.bukti_invoice ?? "-",
          kategori: data.kategori ?? "-",
          event: data.event ?? "-",
          quantity: data.quantity ?? "-",
          kategori_kebutuhan: data.kategori_kebutuhan ?? "-",
          pdf_file: data.pdf_file ?? "",
          excel_file: data.excel_file ?? "",
          kategori_detail: data.kategori_detail ?? "-",
          bukti_reimburse: data.bukti_reimburse ?? "",
          alasan_rejected: data.alasan_rejected ?? "-",
        });
      });
    }
  }, [id, user.username]);

  const uploadBukti = useMutation("upload_bukti", reimburseService.updateBukti, {
    onSuccess: () => window.location.reload(),
    onError: () => notification.onOpen({
      message: "Something went wrong",
      type: "error",
      position: "top",
    })
  });

  const handleUploadBukti = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("id", String(id));
    event.target.files && formData.append("bukti_reimburse", event.target.files[0]);
    uploadBukti.mutate(formData);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const form = useForm({
    defaultValues: {
      alasan_rejected: ""
    },
  });
  const onSubmit = (values: any) => {
    reimburseService.updateStatus({
      status: "rejected",
      approved_by: user.username,
      alasan_rejected: values?.alasan_rejected
    }, id)
      .then(() => window.location.reload())
      .catch(() => notification.onOpen({
        message: "Something went wrong",
        type: "error",
        position: "top",
      }))
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Reimburse Detail</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Reimburse Detail", href: "/app/reimburse" }]}
        label={`Reimburse no ${String(id)}`}
        href={`/app/reimburse/`}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 5, 6]}>
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={{ lg: 3, md: 2 }}>
            <Grid item lg={6} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Karyawan
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.employee_username}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Type
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.type}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Waktu Submission
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {Moment(data.submitted_time).format('YYYY-MM-DD')}
                </Typography>
              </Stack>

              {containsField(data.type, "total") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "total")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {formatToIDR(data.total)}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "bank") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "bank")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.bank}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "rekening") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "rekening")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.rekening}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "atas_nama") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "atas_nama")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.atas_nama}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "start_date") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "start_date")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.start_date !== "-" ? Moment(data.start_date).format('YYYY-MM-DD') : "-"}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "end_date") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "end_date")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.end_date !== "-" ? Moment(data.end_date).format('YYYY-MM-DD') : "-"}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "cabang") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "cabang")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.cabang}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "keterangan") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "keterangan")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.keterangan}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "kategori") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "kategori")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.kategori}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "event") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "event")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.event}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "quantity") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "quantity")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.quantity}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "kategori_kebutuhan") && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "kategori_kebutuhan")?.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.kategori_kebutuhan}
                  </Typography>
                </Stack>
              )}

              {/* <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Kategori Detail
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.kategori_detail}
                </Typography>
              </Stack> */}
            </Grid>

            <Grid item lg={6} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Status
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.status}
                </Typography>
              </Stack>

              {data.status !== "approved" && (
                <Stack sx={{ mt: 2 }} spacing={2} direction="row">
                  <Button variant="contained" onClick={() =>
                    reimburseService.updateStatus({
                      status: "approved",
                      approved_by: user.username,
                      alasan_rejected: ""
                    }, id)
                      .then(() => window.location.reload())
                      .catch(() => notification.onOpen({
                        message: "Something went wrong",
                        type: "error",
                        position: "top",
                      }))
                  }>
                    Approve
                  </Button>
                  {data.status === "requested" && (
                    <Button variant="outlined" onClick={handleOpenModal}>
                      Reject
                    </Button>
                  )}
                </Stack>
              )}

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Diapprove / Direject oleh
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.approved_by}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Waktu Approval / Rejection
                </Typography>
                <Typography
                  variant="body1"
                  color="green"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.approved_time !== "-" ? Moment(data.approved_time).format('YYYY-MM-DD hh-mm-ss') : "-"}
                </Typography>
              </Stack>

              {data.status === "rejected" && data.alasan_rejected !== "" && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    Alasan Rejection
                  </Typography>
                  <Typography
                    variant="body1"
                    color="green"
                    fontWeight="700"
                    sx={{ mt: 0.3 }}
                  >
                    {data.alasan_rejected}
                  </Typography>
                </Stack>
              )}

              {containsField(data.type, "pdf_file") && data.pdf_file !== "" && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "pdf_file")?.note}
                  </Typography>
                  <Button variant="contained" sx={{ mx: 2 }}>
                    <a
                      href={process.env.REACT_APP_BACKEND_URL + "/api/v1/public-reimburse" + data.pdf_file?.slice(28)}
                      download={`Reimburse-${id}-${data.pdf_file.split("/")[data.pdf_file.split("/").length - 1]}`}
                      target="_blank">
                      {`Download PDF Document`}
                    </a>
                  </Button>
                </Stack>
              )}

              {containsField(data.type, "excel_file") && data.excel_file !== "" && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    {containsField(data.type, "excel_file")?.label}
                  </Typography>
                  <Button variant="contained" sx={{ mx: 2 }}>
                    <a
                      href={process.env.REACT_APP_BACKEND_URL + "/api/v1/public-reimburse" + data.excel_file?.slice(28)}
                      download={`Reimburse-${id}-${data.excel_file.split("/")[data.excel_file.split("/").length - 1]}`}
                      target="_blank">
                      {`Download Spreadsheet / Excel File`}
                    </a>
                  </Button>
                </Stack>
              )}

              {data.bukti_reimburse && data.bukti_reimburse !== "" && (
                <Stack sx={{ mt: 2 }}>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    Bukti Reimburse
                  </Typography>
                  <Button variant="contained" sx={{ mx: 2 }}>
                    <a
                      href={process.env.REACT_APP_BACKEND_URL + "/api/v1/public-reimburse" + data.bukti_reimburse?.slice(28)}
                      download={`BuktiReimburse-${id}-${data.bukti_reimburse.split("/")[data.bukti_reimburse.split("/").length - 1]}`}
                      target="_blank">
                      {`Download Bukti Reimburse`}
                    </a>
                  </Button>
                </Stack>
              )}

              {data.status !== "rejected" && (
                <ProtectedPermissionPage acceptPermissions={[1, 5]}>
                  <Stack sx={{ mt: 2 }}>
                    <LoadingButton variant="contained" component="label" sx={{ mt: 2 }} loading={uploadBukti.isLoading}>
                      Upload Bukti Reimburse <input onChange={handleUploadBukti} type="file" hidden />
                    </LoadingButton>
                  </Stack>
                </ProtectedPermissionPage>
              )}
            </Grid>
          </Grid>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ mt: 2 }}>
              <Typography>Input Alasan:</Typography>
              <Controller
                name="alasan_rejected"
                control={form.control}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    size="medium"
                    placeholder="Input Alasan here"
                    {...field}
                    {...config.onEnter(() => {
                      form.handleSubmit(onSubmit);
                    })}
                  />
                )}
              />
            </Box>

            <Button
              sx={{ margin: "5px" }}
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
            >
              Confirm
            </Button>

            <Button
              sx={{ margin: "5px" }}
              variant="outlined"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Box>
        </Modal>
      </ProtectedPermissionPage>
    </MainLayout >
  );
}
