import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import get from "lodash/get";
import moment from "moment";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import MainLayout from "../../components/MainLayout";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import ProtectedPermissionView from "../../components/ProtectedPermissionView";
import useAlert from "../../hooks/useAlert";
import useNotification from "../../hooks/useNotification";
import reimburseService from "../../services/reimburse";
import config from "../../utils/config";
import formatToIDR from "../../utils/formatIDR";
import queryDefault from "../../utils/queryDefault";
import CookieJs from "js-cookie";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function ReimburseList(props: any) {
  const user = JSON.parse(CookieJs.get("USER") || "");
  const [pageSize, setPageSize] = useState<number>(10);
  const [options, setOptions] = useState<any>({
    keyword: "",
    userId: user.id,
  });
  const notification = useNotification();
  const deleteReimburse = useMutation(
    "delete-reimburse",
    reimburseService.deleteReimburse,
    {
      onSuccess: () => {
        listReimburse.refetch();
        notification.onOpen({
          message: "Reimburse was successfully deleted!",
          type: "success",
          position: "top",
        });
      },
      onError: (error: any) => {
        listReimburse.refetch();
        notification.onOpen({
          message:
            error?.response?.data?.message === undefined
              ? "Something went wrong"
              : error?.response?.data?.message,
          type: "error",
          position: "top",
        });
      },
      ...queryDefault,
    }
  );
  const onDelete = (id: number) => {
    alert.onClose();
    deleteReimburse.mutate(id);
  };
  const alert = useAlert();
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete this reimburse?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };
  const listReimburse = useQuery(
    ["reimburse-list", options],
    ({ queryKey }) => {
      return reimburseService.getAllReimburse({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const form = useForm({
    defaultValues: {
      keyword: "",
    },
  });

  const onHandleSearch = useCallback((values: { keyword: any }) => {
    setOptions((prev: any) => ({
      ...prev,
      keyword: values.keyword,
    }));
  }, []);

  const navigate = useNavigate();
  const handleOnCellClick = (params: GridCellParams) => {
    const { row } = params;
    navigate(`/app/reimburse/${row.id}`, { state: row });
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Reimburse List</title>
      </Helmet>

      <Breadcrumb
        label={`Reimburse List`}
        breadcrumbs={[
          { label: "Reimburse Management", href: "/app/reimburse" },
        ]}
      />

      <ProtectedPermissionPage acceptPermissions={[1, 5, 6]}>
        <Box sx={{ mt: 3 }}>
          <Stack direction="row" spacing={1}>
            <Controller
              name="keyword"
              control={form.control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Input Keyword here"
                  {...field}
                  {...config.onEnter(() => {
                    form.handleSubmit(onHandleSearch)();
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          if (field.value) {
                            field.onChange("");
                            setOptions((prev: any) => ({
                              ...prev,
                              keyword: "",
                            }));
                          }
                        }}
                      >
                        {field.value ? (
                          <Close color="error" sx={{ cursor: "pointer" }} />
                        ) : (
                          <Search />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Button
              variant="outlined"
              onClick={form.handleSubmit(onHandleSearch)}
            >
              SEARCH
            </Button>
          </Stack>
        </Box>

        <Box sx={{ mt: 2 }}>
          <ProtectedPermissionView acceptPermissions={[1, 5, 6]}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                component={RouterLink}
                to="/app/reimburse/create"
              >
                Create Reimburse
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  reimburseService.downloadReportReimburse({}).catch(() =>
                    notification.onOpen({
                      message: "Something went wrong!",
                      type: "error",
                      position: "top",
                    })
                  )
                }
              >
                Download Report
              </Button>
            </Stack>
          </ProtectedPermissionView>
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            loading={listReimburse.isLoading}
            getRowId={(record) => get(record, "reimburse_id")}
            rows={get(listReimburse, "data.data", [])}
            rowCount={get(listReimburse, "data.data", []).length}
            page={options.page_number}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            disableSelectionOnClick
            onPageChange={(newPage) => {
              setOptions((prev: any) => ({ ...prev, page_number: newPage }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "submitted_time",
                headerName: "Submit Time",
                flex: 1,
                sortable: true,
                filterable: true,
                minWidth: 150,
                renderCell: (params) => {
                  if (!params.value) {
                    return "-";
                  }

                  return moment(params.value).format("DD/MM/YYYY HH:mm:ss");
                },
              },
              {
                field: "fullname",
                headerName: "Requested By",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Stack
                      onClick={() => handleOnCellClick(params)}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography color="blue">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "division",
                headerName: "Division",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "type",
                headerName: "Type",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "total",
                headerName: "Total",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>
                        {formatToIDR(Number(params.value) || 0)}
                      </Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "status",
                headerName: "Status",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <Typography>{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                flex: 1,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      {params.row.status === "rejected" && (
                        <ProtectedPermissionView acceptPermissions={[1, 5, 6, 99]}>
                          <Button
                            variant="outlined"
                            color="primary"
                            component={RouterLink}
                            to={`/app/reimburse/edit/${params.row.reimburse_id}`}
                          >
                            Edit
                          </Button>
                        </ProtectedPermissionView>
                      )}
                      <ProtectedPermissionView acceptPermissions={[1, 5, 6, 99]}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => onClickDelete(params.row.reimburse_id)}
                        >
                          Delete
                        </Button>
                      </ProtectedPermissionView>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
}
