import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";
import fileDownload from "js-file-download";

const getAllReimburse = async (params: any) => {
  const url = endpoints.BASE_API + "reimburse";
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });
  return response;
};

const getReimburseById = async (id: number) => {
  const url = endpoints.BASE_API + "reimburse/" + id;
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const deleteReimburse = async (id: number) => {
  const url = endpoints.BASE_API + "reimburse/" + id;
  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const createReimburse = async (params: any) => {
  const url = endpoints.BASE_API + "reimburse";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateReimburse = async (params: any) => {
  const url = endpoints.BASE_API + "reimburse/" + params.get("id");
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateStatus = async (params: any, id: any) => {
  const url = endpoints.BASE_API + "reimburse/status/" + id;
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateBukti = async (params: any) => {
  const url = endpoints.BASE_API + "reimburse/bukti/" + params.get("id");
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const downloadReportReimburse = async (params: any) => {
  const url = endpoints.BASE_API + "reimburse/export";
  await axios
    .get(url, {
      headers: {
        Authorization: accessToken,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      params,
      responseType: "arraybuffer",
    })
    .then((res) => {
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileDownload(blob, `reimburse_report.xlsx`);
    });
};

const reimburseService = {
  getReimburseById,
  getAllReimburse,
  updateReimburse,
  deleteReimburse,
  createReimburse,
  updateStatus,
  updateBukti,
  downloadReportReimburse,
};

export default reimburseService;
