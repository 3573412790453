type FormField = {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  note?: string;
};

type FormConfig = {
  [key: string]: FormField[];
};

const formConfig: FormConfig = {
  Renovasi: [
    {
      name: "name",
      label: "Nama Karyawan",
      type: "text",
      disabled: true
    },
    {
      name: "division",
      label: "Divisi / Role",
      type: "text",
      disabled: true
    },
    {
      name: "start_date",
      label: "Tanggal Awal Renovasi",
      type: "date",
      required: true,
    },
    {
      name: "end_date",
      label: "Tanggal Penyelesaian Renovasi",
      type: "date",
      required: false,
    },
    { name: "cabang", label: "Cabang", type: "text", required: true },
    {
      name: "keterangan",
      label: "Keterangan Renovasi",
      type: "text",
      required: true,
    },
    {
      name: "total",
      label: "Jumlah yang harus dibayarkan",
      type: "number",
      required: true,
    },
    {
      name: "pdf_file",
      label: "Document PDF",
      type: "file",
      note: "Bukti Invoice, KTP / NPWP (Format PDF)",
    },
    {
      name: "excel_file",
      label: "FORECAST & RAB (Spreadsheet / Excel)",
      type: "file",
      required: true,
    },
    { name: "bank", label: "Bank Transfer", type: "text", required: true },
    { name: "rekening", label: "No Rekening", type: "text", required: true },
    {
      name: "atas_nama",
      label: "Atas Nama Rekening",
      type: "text",
      required: true,
    },
  ],
  "Beli Asset": [
    {
      name: "name",
      label: "Nama Karyawan",
      type: "text",
      disabled: true
    },
    {
      name: "division",
      label: "Divisi / Role",
      type: "text",
      disabled: true
    },
    {
      name: "start_date",
      label: "Tanggal Pembelian",
      type: "date",
      required: true,
    },
    { name: "cabang", label: "Cabang", type: "text", required: true },
    {
      name: "event",
      label: "Event",
      type: "text",
      required: true,
    },
    {
      name: "keterangan",
      label: "Keterangan Pembelian Asset",
      type: "text",
      required: true,
    },
    {
      name: "total",
      label: "Jumlah yang harus dibayarkan",
      type: "number",
      required: true,
    },
    {
      name: "pdf_file",
      label: "Document PDF",
      type: "file",
      note: "Bukti Invoice (Format PDF), KTP / NPWP (Format PDF)",
    },
    { name: "bank", label: "Bank Transfer", type: "text", required: true },
    { name: "rekening", label: "No Rekening", type: "text", required: true },
    {
      name: "atas_nama",
      label: "Atas Nama Rekening",
      type: "text",
      required: true,
    },
  ],
  "Beli Bahan Baku": [
    {
      name: "name",
      label: "Nama Karyawan",
      type: "text",
      disabled: true
    },
    {
      name: "division",
      label: "Divisi / Role",
      type: "text",
      disabled: true
    },
    {
      name: "start_date",
      label: "Tanggal Pembelian",
      type: "date",
      required: true,
    },
    { name: "cabang", label: "Cabang", type: "text", required: true },
    {
      name: "keterangan",
      label: "Keterangan Pembelian Bahan Baku",
      type: "text",
      required: true,
    },
    { name: "quantity", label: "Berapa Dus", type: "number", required: true },
    {
      name: "total",
      label: "Jumlah yang harus dibayarkan",
      type: "number",
      required: true,
    },
    {
      name: "pdf_file",
      label: "Document PDF",
      type: "file",
      note: "Bukti Invoice (Format PDF), KTP / NPWP (Format PDF)",
    },
    { name: "bank", label: "Bank Transfer", type: "text", required: true },
    { name: "rekening", label: "No Rekening", type: "text", required: true },
    {
      name: "atas_nama",
      label: "Atas Nama Rekening",
      type: "text",
      required: true,
    },
  ],
  Reimburse: [
    {
      name: "name",
      label: "Nama Karyawan",
      type: "text",
      disabled: true
    },
    {
      name: "division",
      label: "Divisi / Role",
      type: "text",
      disabled: true
    },
    {
      name: "start_date",
      label: "Tanggal Pemakaian Uang",
      type: "date",
      required: true,
    },
    {
      name: "keterangan",
      label: "Keterangan Reimburse",
      type: "text",
      required: true,
    },
    {
      name: "pdf_file",
      label: "Document PDF",
      type: "file",
      note: "Bukti Invoice, Bukti Trf / Bukti telah dibayarkan (Format PDF), KTP / NPWP (Format PDF)",
    },
    { name: "bank", label: "Bank Transfer", type: "text", required: true },
    { name: "rekening", label: "No Rekening", type: "text", required: true },
    {
      name: "atas_nama",
      label: "Atas Nama Rekening",
      type: "text",
      required: true,
    },
  ],
  KOL: [
    {
      name: "start_date",
      label: "Tanggal Pengisian",
      type: "date",
      required: true,
    },
    { name: "name", label: "Nama PIC", type: "text", required: true },
    { name: "kategori", label: "Kategori KOL", type: "text", required: true },
    {
      name: "keterangan",
      label: "Keterangan KOL",
      type: "text",
      required: true,
    },
    {
      name: "pdf_file",
      label: "Document PDF",
      type: "file",
      note: "Bukti Invoice & NPWP / KTP (Format PDF)",
    },
    { name: "bank", label: "Bank Transfer", type: "text", required: true },
    { name: "rekening", label: "No Rekening", type: "text", required: true },
    {
      name: "atas_nama",
      label: "Atas Nama Rekening",
      type: "text",
      required: true,
    },
  ],
  "Bayar Listrik & Air": [
    {
      name: "name",
      label: "Nama Karyawan",
      type: "text",
      disabled: true
    },
    {
      name: "division",
      label: "Divisi / Role",
      type: "text",
      disabled: true
    },
    { name: "kategori", label: "Kategori", type: "text", required: true },
    {
      name: "keterangan",
      label: "Keterangan Kebutuhan",
      type: "text",
      required: true,
    },
    {
      name: "pdf_file",
      label: "Document PDF",
      type: "file",
      note: "Bukti Invoice / Nota (Format PDF), NPWP / KTP (Format PDF)",
    },
    { name: "bank", label: "Bank Transfer", type: "text", required: true },
    {
      name: "rekening",
      label: "Nomor Virtual Account / Nomor Rekening",
      type: "text",
      required: true,
    },
    {
      name: "atas_nama",
      label: "Atas Nama Pemilik",
      type: "text",
      required: true,
    },
  ],
  Loading: [
    {
      name: "name",
      label: "Nama Karyawan",
      type: "text",
      disabled: true
    },
    {
      name: "division",
      label: "Divisi / Role",
      type: "text",
      disabled: true
    },
    {
      name: "kategori",
      label: "Kategori Loading",
      type: "text",
      required: true,
    },
    {
      name: "kategori_kebutuhan",
      label: "Kategori Kebutuhan",
      type: "text",
    },
    {
      name: "start_date",
      label: "Tanggal Loading IN",
      type: "date",
      required: true,
    },
    {
      name: "end_date",
      label: "Tanggal Loading OUT",
      type: "date",
      required: true,
    },
    {
      name: "keterangan",
      label: "Keterangan Loading",
      type: "text",
      required: true,
    },
    {
      name: "total",
      label: "Jumlah Uang yang harus ditransfer",
      type: "number",
      required: true,
    },
    {
      name: "pdf_file",
      label: "Document PDF",
      type: "file",
      note: "Bukti Invoice, Struk, Nota (Format PDF), KTP Perbantuan",
    },
    { name: "bank", label: "Bank Transfer", type: "text", required: true },
    {
      name: "rekening",
      label: "No Rekening / Virtual Account",
      type: "text",
      required: true,
    },
    {
      name: "atas_nama",
      label: "Atas Nama Pemilik Rekening",
      type: "text",
      required: true,
    },
  ],
  "Perjalanan Dinas": [
    {
      name: "name",
      label: "Nama Karyawan",
      type: "text",
      disabled: true
    },
    {
      name: "division",
      label: "Divisi / Role",
      type: "text",
      disabled: true
    },
    {
      name: "kategori",
      label: "Kategori Perjalanan Dinas",
      type: "text",
      required: true,
    },
    {
      name: "excel_file",
      label: "Format Approval Perjalanan Dinas dari HR (Spreadsheet / Excel)",
      type: "file",
      required: true,
    },
    {
      name: "pdf_file",
      label: "Document PDF",
      type: "file",
      note: "Bukti Invoice Permintaan (Format PDF)",
    },
    {
      name: "keterangan",
      label: "Keterangan Permintaan Pembayaran",
      type: "text",
      required: true,
    },
    { name: "bank", label: "Bank Transfer", type: "text", required: true },
    { name: "rekening", label: "No Rekening", type: "text", required: true },
    {
      name: "atas_nama",
      label: "Atas Nama Rekening",
      type: "text",
      required: true,
    },
  ],
  "Visit Store": [
    {
      name: "name",
      label: "Nama Karyawan",
      type: "text",
      disabled: true
    },
    {
      name: "division",
      label: "Divisi / Role",
      type: "text",
      disabled: true
    },
    {
      name: "pdf_file",
      label: "Document PDF",
      type: "file",
      note: "Surat Tugas (Format PDF)",
    },
    { name: "bank", label: "Bank Transfer", type: "text", required: true },
    { name: "rekening", label: "No Rekening", type: "text", required: true },
    {
      name: "atas_nama",
      label: "Atas Nama Rekening",
      type: "text",
      required: true,
    },
  ],
};

export default formConfig;
